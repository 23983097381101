export const COLORS = {
  text: {
    light: "#1b1b1b", // eerie black
    dark: "#faebd7", // antique white
  },
  background: {
    light: "#ffffff", // white
    dark: "#1d2951", // navy navy blue
  },
  primary: {
    light: "#00009c", // cerulean blue
    dark: "#8db600", // apple green
  },
  secondary: {
    light: "#5a4fcf", // iris
    dark: "#fa8072", // salmon
  },
  tertiary: {
    light: "#5a4fcf", // iris
    dark: "#f08080", // salmon
  },
  // Grays, scaling from least-noticeable to most-noticeable
  gray300: {
    light: "hsl(0deg, 0%, 70%)",
    dark: "hsl(0deg, 0%, 30%)",
  },
  gray500: {
    light: "hsl(0deg, 0%, 50%)",
    dark: "hsl(0deg, 0%, 50%)",
  },
  gray700: {
    light: "hsl(0deg, 0%, 30%)",
    dark: "hsl(0deg, 0%, 70%)",
  },
};

export const COLOR_MODE_KEY = "color-mode";
export const INITIAL_COLOR_MODE_CSS_PROP = "--initial-color-mode";
